import React from "react"

import Layout from "../components/Layout"

import SliderSection from "../components/SliderSection"

import ContentSection from "../components/ContentSection"

import Column from "../components/Column"

import ContactSection from "../components/ContactSection"

import Slide from "../components/Slide"

import "../styles/oNama.css"

import slideImage0 from "./../../slike/stock-photo-surveyor-engineer-is-measuring-level-on-construction-site-surveyors-ensure-precise-measurements-473240869.jpg";

export default function ONama() {
	
	return (
		<Layout>
			
			<SliderSection>
				<Slide width="100%" activeSlide={ 0} index={ 0} backgroundImage={ slideImage0} >
					<h3 className="kn-slide-title">O nama</h3>
				</Slide>
			</SliderSection>
			
			<ContentSection background="linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%)" className="kn-first-background">
				<Column width="50%" className="kn-usluga-column-text">
					<p>GEO KONTURA d.o.o. je tvrtka čija je osnovna djelatnost pružanje geodetskih usluga.
					<br/>Posjedujemo sve potrebne suglasnosti i ovlaštenja za obavljanje stručnih geodetskih poslova.
					<br/>Ured nam se nalazi u Biogradu na Moru, Ul. dr. Franje Tuđmana 82, (Bure centar, 1. kat).
					<br/>Koristimo modernu i inovativnu geodetsku i informatičku opremu sa licenciranim programima za obradu i analizu podataka.</p>
				</Column>
			</ContentSection>
			
			<ContactSection id="kontakt" />
		</Layout>
	)
}